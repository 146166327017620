import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { cpfMask } from "cross-cutting/masks/cpf";
import { IAddress } from "models/address/IAddress";
import {
  ICardPaymentGuestRequest,
  IOwnerAddressPaymentGuest,
  IOwnerPaymentGuest,
} from "models/paymentGuest/IPaymentGuestForm";
import NewAddressForm from "pages/newAddress/NewAddressForm";
import { DeliveryAddressItem } from "pages/payment/deliverySelect/deliveryAddressItem/DeliveryAddressItem";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { cnpjMask } from "utils/mask/cnpj";
import { v4 } from "uuid";
import styles from "./CreditForm.module.scss";

export interface ICreditForm {
  formRef: React.RefObject<HTMLDivElement>;
  card: ICardPaymentGuestRequest;
  setCard: (value: React.SetStateAction<ICardPaymentGuestRequest>) => void;
  errors: any;
  owner: IOwnerPaymentGuest;
  setOwner: React.Dispatch<React.SetStateAction<IOwnerPaymentGuest>>;
  address: IOwnerAddressPaymentGuest;
  setAddress: React.Dispatch<React.SetStateAction<IOwnerAddressPaymentGuest>>;
}

const CreditForm: FC<ICreditForm> = ({ card, setCard, formRef, errors, address, owner, setAddress, setOwner }) => {
  const [holder, setHolder] = useState<string>("fisica");
  const [openAddressForm, setOpenAddressForm] = useState(false);

  const numberMask = (value: string) => {
    return value.replace(/[^0-9]/g, "").substr(0, 16);
  };
  const cvvMask = (value: string) => {
    return value.replace(/[^0-9]/g, "").substr(0, 4);
  };

  const onSubmitAddress = useCallback((address: IAddress) => {
    setAddress(address);
    setOpenAddressForm(false);
  }, [setAddress]);

  const onChangeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    ev.persist();

    if (ev.target.name === "number") {
      setCard((prev) => ({
        ...prev,
        [ev.target.name]: numberMask(ev.target.value),
      }));
    } else if (ev.target.name === "securityCode") {
      setCard((prev) => ({
        ...prev,
        [ev.target.name]: cvvMask(ev.target.value),
      }));
    } else {
      setCard((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }
  }, [setCard]);

  const changeOwnerHandle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      ev.persist();
      if (ev.target.name === "document") {
        if (holder === "fisica") {
          setOwner((prev) => ({
            ...prev,
            [ev.target.name]: cpfMask(ev.target.value),
          }));
        } else {
          setOwner((prev) => ({
            ...prev,
            [ev.target.name]: cnpjMask(ev.target.value),
          }));
        }
      } else {
        setOwner((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
      }
    },
    [holder, setOwner]
  );

  useEffect(() => {
    setOwner((prev) => ({ ...prev, document: "" }));

    return () => {};
  }, [holder, setOwner]);

  const showAddress = useMemo(
    () =>
      address.street
        ? `${address.street}, ${address.number} - ${address.neighborhood}, ${address.city}/${address.state}`
        : null,
    [address]
  );

  const handleFocus = useCallback(() => {
    formRef?.current?.scroll({ top: 300, behavior: "smooth" });
  }, [formRef]);

  return (
    <div id={styles.CreditForm}>
      <Container maxWidth="xs" className={styles.container}>
        <form>
          <Grid spacing={1} alignContent="center" alignItems="center" container>
            <Grid item xs={12}>
              <TextField
                required
                autoComplete="cc-number"
                helperText={errors.number}
                error={!!errors.number}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={card.number}
                inputProps={{
                  inputMode: "tel",
                }}
                name="number"
                label="Número do cartão"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={card.ownerName}
                helperText={errors.ownerName}
                error={!!errors.ownerName}
                name="ownerName"
                label="Nome no cartão"
                />
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                required
                autoComplete="cc-exp-month"
                variant="outlined"
                fullWidth
                value={card.month}
                onChange={onChangeHandle}
                name="month"
                label="Mês"
                placeholder="mm"
                error={!!errors.month}
                helperText={errors.month}
                >
                <MenuItem style={{ textAlign: "left" }} value="01">
                  01
                </MenuItem>
                <MenuItem value="02">02</MenuItem>
                <MenuItem value="03">03</MenuItem>
                <MenuItem value="04">04</MenuItem>
                <MenuItem value="05">05</MenuItem>
                <MenuItem value="06">06</MenuItem>
                <MenuItem value="07">07</MenuItem>
                <MenuItem value="08">08</MenuItem>
                <MenuItem value="09">09</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                required
                autoComplete="cc-exp-year"
                variant="outlined"
                fullWidth
                value={card.year}
                onChange={onChangeHandle}
                name="year"
                label="Ano"
                placeholder="aa"
                error={!!errors.year}
                helperText={errors.year}
                >
                <MenuItem value="2024">24</MenuItem>
                <MenuItem value="2025">25</MenuItem>
                <MenuItem value="2026">26</MenuItem>
                <MenuItem value="2027">27</MenuItem>
                <MenuItem value="2028">28</MenuItem>
                <MenuItem value="2029">29</MenuItem>
                <MenuItem value="2030">30</MenuItem>
                <MenuItem value="2031">31</MenuItem>
                <MenuItem value="2032">32</MenuItem>
                <MenuItem value="2033">33</MenuItem>
                <MenuItem value="2034">34</MenuItem>
                <MenuItem value="2035">35</MenuItem>
                <MenuItem value="2036">36</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                autoComplete="cc-exp-csc"
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={card.securityCode}
                helperText={errors.securityCode}
                error={!!errors.securityCode}
                name="securityCode"
                label="CVV"
                inputProps={{ inputMode: "numeric" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                onChange={changeOwnerHandle}
                value={owner.name}
                helperText={errors.owner.name}
                error={!!errors.owner.name}
                name="name"
                label="Nome do titular"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="pessoa"
                  name="gender1"
                  value={holder}
                  onChange={(ev, value) => setHolder(value)}
                  row
                >
                  <FormControlLabel
                    value="fisica"
                    control={<Radio color="primary" />}
                    label="CPF"
                  />
                  <FormControlLabel
                    value="juridica"
                    control={<Radio color="primary" />}
                    label="CNPJ"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                onChange={changeOwnerHandle}
                value={owner.document}
                inputProps={{
                  inputMode: "tel",
                }}
                onFocus={handleFocus}
                helperText={errors.owner.document}
                error={!!errors.owner.document}
                name="document"
                label={`${holder === "fisica" ? "CPF" : "CNPJ"}`}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                onChange={changeOwnerHandle}
                value={owner.email}
                helperText={errors.owner.email}
                error={!!errors.owner.email}
                inputProps={{
                  inputMode: "email",
                }}
                name="email"
                label="E-mail"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                fullWidth
                onChange={changeOwnerHandle}
                value={owner.phoneNumber}
                helperText={errors.owner.phoneNumber}
                error={!!errors.owner.phoneNumber}
                name="phoneNumber"
                label="Telefone"
              />
            </Grid>

            <Grid style={{}} item xs={12} className={styles.address}>
              <div
                onClick={() => {
                  setOpenAddressForm(true);
                }}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Endereço"
                  className={styles.textField}
                  value={showAddress}
                  helperText={errors.owner.address}
                  error={!!errors.owner.address}
                  InputLabelProps={{
                    shrink: !!address.street,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <DeliveryAddressItem
                onClickEdit={() => {
                  setOpenAddressForm(true);
                }}
                onClick={() => setOpenAddressForm(true)}
                address={{ ...address, id: v4() }}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Modal
        open={openAddressForm}
        onClose={() => setOpenAddressForm(false)}
        className={styles.addressContainer}
      >
        <Paper className={styles.paper}>
          <NewAddressForm
            onSubmit={onSubmitAddress}
            onClose={() => setOpenAddressForm(false)}
          />
        </Paper>
      </Modal>
    </div>
  );
};

export default CreditForm;
