import { Button, Skeleton } from "@mui/material";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Print } from "assets/icons/print.svg";
import { useUi } from "context/ui/UIContext";
import {
  IPaymentLinkResponse,
  TicketGetResponse,
} from "models/paymentLink/IPaymentLinkResponse";
import React, { FC, useCallback, useState } from "react";
import PaymentLinkApi from "services/Api/paymentLink/PaymentLinkApi";
import Barcode from "react-barcode";
import styles from "./Ticket.module.scss";
import TicketForm, { ITicketFormValues } from "../ticketForm/TicketForm";
import { IPayerErrors, validate } from "../ticketForm/validate";
import { ReactComponent as Load } from "assets/icons/loading.svg";

export interface ITicket {
  paymentLink: IPaymentLinkResponse;
}

const Ticket: FC<ITicket> = ({ paymentLink }) => {
  const { toast, isMobile } = useUi();

  const [ticket, setTicket] = useState<TicketGetResponse | null>(null);
  const [payerValues, setPayerValues] = useState<ITicketFormValues>({
    PayerDocumentNumber: "",
    PayerEmail: "",
    PayerFirstName: "",
    PayerLastName: "",
  });
  const [errors, setErrors] = useState<IPayerErrors>({
    PayerDocumentNumber: "",
    PayerEmail: "",
    PayerFirstName: "",
    PayerLastName: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChangePayerValues = useCallback((name: string, value: string) => {
    setPayerValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const generateTicket = useCallback(
    () => {
      if (paymentLink && validate(payerValues, setErrors)) {
        const paymentLinkApi = PaymentLinkApi();
        setLoading(true);
        paymentLinkApi
          .ticketPayment({
            OwnerId: paymentLink.localId,
            Description: paymentLink.description,
            ProductId: paymentLink.productId,
            TransactionAmount: paymentLink.price,
            ...payerValues
          })
          .then((response) => {
            paymentLinkApi
            .ticketPaymentStatus(response.BoletoId)
            .then((res) => {
                setTicket(res);
              })
              .catch(() => {
                paymentLinkApi
                  .ticketPaymentStatus(response.BoletoId)
                  .then((res) => {
                    setTicket(res);
                  })
                  .catch(() => {
                    toast(
                      "A emissão do boleto falhou, tente novamente",
                      "error"
                    );
                  });
              });
          })
          .catch(() => {
            toast("A emissão do boleto falhou, tente novamente", "error");
          })
          .finally(() => {
            setLoading(false);
          })
      }
    },
    [payerValues, paymentLink, toast]
  );

  const copyBarCodeToClipboard = useCallback(() => {
    if (ticket?.BarCode) {
      navigator.clipboard.writeText(ticket?.BarCode);
      toast("Código copiado para área de transferência", "success");
    }
  }, [ticket, toast]);

  const openNewTab = useCallback(() => {
    if (ticket?.Url) {
      window.open(ticket.Url, "_blank");
    }
  }, [ticket]);

  return (
    <div id={styles.Ticket}>
      <div className={styles.title}>Realize o pagamento através do boleto</div>
      {ticket ? (
        <>
          <div className={styles.description}>
            Realize a leitura do código de barras abaixo ou imprima seu boleto.
          </div>
          <div className={styles.ticketContainer}>
            {!ticket?.BarCode ? (
              <Skeleton
                width={isMobile ? 350 : 495}
                height={isMobile ? 70 : 90}
                variant="rectangular"
                style={{ marginTop: isMobile ? 35 : 20 }}
              />
            ) : (
              <div className={styles.barcode}>
                <Barcode value={ticket.BarCode} />
              </div>
            )}
          </div>
          <div
            className={styles.ticketFooter}
            style={{ marginBottom: isMobile ? 16 : undefined }}
          >
            <div>
              <div
                onClick={copyBarCodeToClipboard}
                className={!ticket?.BarCode ? styles.disabled : ""}
              >
                <Copy />
                Copiar código
              </div>
              <p>ou</p>
              <div
                onClick={openNewTab}
                className={!ticket?.BarCode ? styles.disabled : ""}
              >
                <Print />
                Imprimir boleto
              </div>
            </div>
            <p style={{ whiteSpace: isMobile ? "normal" : undefined }}>
              O pagamento via boleto pode demorar até 2 dias uteis para
              confirmação
            </p>
          </div>
        </>
      ) : (
        <>
          <TicketForm
            handleChangeValues={handleChangePayerValues}
            values={payerValues}
            errors={errors}
          />
          <Button
            size="large"
            variant="contained"
            onClick={generateTicket}
            disabled={loading}
          >
            {loading ? <Load/> : "Gerar boleto"}
          </Button>
        </>
      )}
    </div>
  );
};

export default Ticket;
